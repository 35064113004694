<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="3" title="Workflow Step" icon="" />
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'

export default {
    name: 'WorkFlowObj',
    components: { TopNavigation, Alert, Popper },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        return {
            route, router, alert, closeAlert, func
        }
    }

}
</script>

<style>

</style>